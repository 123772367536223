.be-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  background: black;
  width: 800px;
  height: 480px;
  z-index: 3000;
  opacity: 0.8;
}

.background-panel {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #2b333f;
  z-index: 1;
  opacity: 95%;
}

.suneditor-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;
}

.suneditor-wrapper .sun-editor {
  margin: 0 auto;
  border: 15px solid rgba(0, 0, 0, 0.2);
}
.editBoardEvtButton-wrapper{
  position: absolute;
  right: 50%;
  margin-top: 5px;
  margin-right: -400px;
}
.editBoardEvtButton-wrapper button:nth-of-type(1){
  margin-right: 7px;
  background-color: #ef9a9a;
}
.opacity-level-wrapper{
  position: absolute;
    z-index: 99999999;
    left: 50%;
    margin-left: -400px;
    top: 550px;
    background: #fff;
    border-radius: 5px;
    padding: 0 5px;
}
