* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', Arial;
  color: #333333;
}

body {
  overflow-y: hidden;
}

*.link {
  opacity: .7;
  cursor: pointer;
}

*.link:hover {
  opacity: 1;
}

html.lightgrey-bgd {
  background: #f9f9f9;
}

.dropzone-container.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
