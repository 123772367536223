.project-body {
  width: 95%;
  margin-left: 4%;
}

.project-content.card-panel {
  height: 450px;
  padding: 0;
  margin: 5px auto;
}

.video-sidebar {
  position: absolute;
  left: 10px;
  top: 55px;
}

.video-sidebar button {
  display: block;
  margin: 10px 0;
}

.project-content::after {
  content: '';
  display: block;
  clear: both;
}

.project-video, .project-data {
  float: left;
}

.project-video {
  width: 800px;
  height: 450px;
}

.project-data {
  width: calc(100% - 800px);
  padding: 20px;
}

.project-data-form {
  display: grid;
  grid-template-columns: 40% 40% 19%;
  margin-bottom: 15px;
}

.project-data-form .next-url {
  grid-column: 1 / span 2;
}

.project-data-form .ctrl {
  margin: 5px 15px;
}

.project-data-form .ctrl.user-controls {
  display: flex;
  align-items: center;
  margin: 0 15px;
}

.project-data-form .ctrl.user-controls label {
  position: static;
  padding-bottom: 25px;
  margin-left: 10px;
  font-size: 0.9rem;
}

.project-data-form .ctrl.user-controls #userControls {
  pointer-events: auto;
  position: static;
}

.project-data-form .ctrl.reporting-problem {
  display: flex;
  align-items: center;
  margin: 0 15px;
}

.project-data-form .ctrl.reporting-problem #isReportingProblem {
  position: static
}

.project-data-form .ctrl.reporting-problem label{
  position: static;
  padding-bottom: 25px;
  margin-left: 10px;
}

#project .dropzone-body {
  text-align: center;
}
