.events.card-panel {
  margin: 40px auto;
  height: 430px;
  width: 100%;
  overflow-y: auto;
  padding: 20px 0;
}

.events-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.events-title h6 {
  margin: 0;
}
.events-body{
  margin-top: 15px;
}
.evt {
  margin: 5px 0;
  padding: 0px 5px 5px;
  border: 1px solid #949494;
  margin: 10px 5px;
  border-radius: 5px;
}

.evt-ctrl {
  float: right;
}
.evt-ctrl i{
  font-size: 30px;
}
.evt.active {
  background: #3bc9ff14;
  /* border: 1px solid #6c96b76e; */
}

.evt input[type=number]:not(.browser-default).time {
  width: 50px;
  margin: 0;
  text-align: center;
}

/*  STOP EVENTS */
.with-subevts::after {
  content: '';
  display: block;
  clear: both;
}

.with-subevts-left, .with-subevts-right {
  float: left;
}

.with-subevts-left {
  width:15%;
}

.with-subevts-right {
  width: 100%;
}

.with-subevts input[type=text]:not(.browser-default) {
  margin: 0 10px;
}

.evt.with-subevts input[type=number]:not(.browser-default).time {
  width: 80px;
  margin: 0 10px;
}

.with-subevts-left {
  display: flex;
  align-items: center;
}
/* .evt {
  border: 1px solid #949494;
  margin: 10px 5px;
  border-radius: 5px;
} */
.stop-evt .evt-icon {
  top: 2px;
}

.subevent {
  margin-left: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.subevent img {
  width: 56px;
  position: relative;
  top: 15px;
  left: 5px;
}

.subevent select {
  width: 150px;
  padding: 5px;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  height: auto;
  margin-top: 20px;
}

.subevent .ctrl {
  margin: 0 10px;
}

.subevent .ctrl.next input[type=text] {
  width: 400px;
}

.subevt-ctrls i {
  position: relative;
  top: 15px;
  left: 5px;
}

.subevent .selected-icon-indicator {
  visibility: hidden;
}

.subevent .ctrl.next {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.subevent .ctrl.next input[type="checkbox"]:not(:checked), input[type="checkbox"]:checked {
  position: static;
  opacity: 1;
  pointer-events: auto;
}

.subevent .ctrl.next label {
  position: relative;
  left: 5px;
  top: 2px;
}

/* ARROW EVENTS */
.arrow-evt .evt-icon {
  top: 3px;
}

/* BOARD EVENTS */
.board-evt {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.board-evt input[type='text']:not(.browser-default) {
  margin: 0 8px;
}

.board-evt input[type='text'].time {
  width: 80px;
}

.board-evt i:not(.evt-icon) {
  margin-left: 19px;
}
.board-evt input[type='text'].boardName{
  width: 60%
}

.board-evt.evt .redirectName {
  width:200px;
}

.board-evt button{
  margin-bottom: 3px;
  z-index: 0;
}
.evt-icon {
  position: relative;
  top: -8px;
}
.no-events {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.intro-dl {
  display: flex;
}

.intro-dl input[type=number]:not(.browser-default) {
  width: 50px;
  margin: 0 5px
}

.continue.ctrl {
  display: flex;
  align-items: flex-end;
  margin: 0 10px;

}
.continue.ctrl input[type=number]:not(.browser-default) {
  margin: 0px;
}
.continue.ctrl .checkbox-wrapper {
  margin: 5px 20px;
  display: flex;
  position: relative;
}

.continue.ctrl label {
  position: relative;
  left: 15px;
  bottom: 2px;
}
