.canvas-video-wrapper {
  position: relative;
}

.video.video-js  {
  position: absolute;
  top: 0;
  left: 0;
}

.video {
  outline: none;
};

.video-object {
  position: absolute;
  z-index: 1200;
  color: #6699CC;
  width: 24px;
  height: 24px;
};

.video-js .vjs-control-bar {
  display: flex !important;
  visibility: visible !important;
  bottom: -100px !important;
}

.video-js .vjs-big-play-button {
  display: none !important;
}

.canvas-container {
  z-index: 2000 !important;margin: 0 auto !important;
}

 .vjs-icon-placeholder:before {
    color: #fff !important;
}

.vjs-remaining-time-display {
  color: #ffffff;
}

.video-js .vjs-current-time, .vjs-no-flex .vjs-current-time,  .vjs-current-time span{
  display: block !important;
  color: #fff !important;
}

.video-js .vjs-remaining-time {
    display: none;
}

.selected-evt-time {
  bottom: -10px;
position: absolute;
}
