#projects {
  height: 100vh;
  overflow-y: auto;
}

.projects-title, .projects-body {
  width: 75%;
  margin: auto;
}

.projects-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 35px auto;
}

.btn-flex.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.projects-title .btn-flex.btn i {
  position: relative;
  left: -2px;
  top: -1px;
}

.project-ctrls i {
  margin: 0 2px;
}
.projects-body img{
  display: block;
  margin: 0 auto;
}
.projects-body table {
  border: 1px solid #dbdbdb;;
}

.projects-body table thead {
  background: #9e9e9e;
  border: 1px solid #9e9e9e;;
}

.projects-body table thead * {
  color: #fff;
}

.row-title {
  background: #f5f5f5;
}

th, td{
  padding-left: 15px !important
}

.filterable {
  position: relative;
  padding: 0;
}

.filterable i {
  position: absolute;
  top: 16px;
  left: 14px;
  font-size: 20px;
}

.filterable input::placeholder {
  color: #fff;
  font-weight: 600;
}

.filterable input.invisible-borders {
  border: 0 solid transparent;
  padding-left: 22px;
  margin: 0;
  width: 70%;
  box-shadow: none;
  height: 2rem;
}

.filterable input.invisible-borders:hover, .filterable input.invisible-borders:focus, .filterable input.invisible-borders:active {
  border: 0px solid transparent !important;
  box-shadow: none !important;
}

.toggle-arrow {
  transition: all .5s;
  position: relative;
  left: 30px;
  top: 5px;
  outline: none;
}

.toggle-arrow.arrow-up {
  transform: rotate(180deg);
}

.toggle-arrow.arrow-down {
  transform: rotate(0deg);
}
