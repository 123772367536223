.link-preview {
  position: fixed;
  box-shadow: 0 0 5px #cfcfcf;
  width: 520px;
  background: #fbfbfb;
  padding: 0px 5px;
  z-index: 2;
  top: 20px;
  left: 20px;
  display: none;
  align-items: center;
  z-index: 5000;
}

.link-preview input[type=text]:not(.browser-default),
.link-preview input[type=text]:not(.browser-default):focus:not([readonly]) {
    margin-right: 5px;
    padding-left: 10px;
    border-bottom: 0;
    box-shadow: 0 0 0 0 #f48fb1;
    margin-bottom: 0 !important;
  }

.link-preview .open-link {
  margin-top: 6px;
}
