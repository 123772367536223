.login {
  width: 40%;
  margin: 250px auto;
}

.login h3, .login form {
  margin-bottom: 2em;
}

.login .input-field {
  margin: 10px 0 40px;
}

.login input[type=text], .login input[type=password] {
  padding-left: 10px !important;
}

.login label {
  pointer-events: none !important;
}

.login .logo-wrapper {
  text-align: center;
}

.login .logo-wrapper img {
  height: 150px;
}
