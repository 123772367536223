.app-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2500;
  width: 100vw;
  height: 100vh;
}

.app-modal-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, .5);
}

.app-modal .card {
  width: 700px;
  margin: 150px auto;
  border-radius: 5px;
}

.app-modal .card-title {
  padding: 0.2em 1em;
  border-bottom: 1px solid #c0c0c059;
}

.app-modal .back-video-body {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.app-modal .popup-event-body {
  width: 680px;
  padding: 20px;
}

.popup-event-footer {
  text-align: right;
  padding: 0 40px 20px;
}

.popup-event-footer .btn + .btn {
  margin-left: 5px;
}

.back-video-title {
  padding: 1em 1.2em;
}

.back-video-body .dropzone-container > div {
  width: 660px;
}

.back-video-footer {
  text-align: center;
  padding-bottom: 20px;
}

.card-footer {
  padding: 1em;
  text-align: right;
  padding: 24px 2em 1.5em;
}
