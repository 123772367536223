@media only screen and (max-width: 1919px) {
  .video-sidebar {
    left: 1px;
  }
}

@media only screen and (max-width: 1580px) {
  .project-body {
    width: 95%;
    margin-left: 4%;
  }

  .project-data {
    padding: 5px;
  }

  .video-sidebar {
    left: 1px;
  }
  .subevent .ctrl.next {
    flex: auto;
}
  .subevent .ctrl.next input[type=text] {
    width: 100%;

  }
  .with-subevts input[type=text]:not(.browser-default) {
    margin: 0 3px;
  }
  .continue input[type=number]:not(.browser-default){
    margin: 0px;
  }
  .continue.ctrl{
    flex:auto;
  }
  .continue.ctrl .checkbox-wrapper {
    margin: 0px 5px;
  }
  .subevt-ctrls i {
    position: relative;
    top: 10px;
  }

}

@media only screen and (max-height: 900px) {
  .project-data .ctrl input {
    height: 2em !important;
  }

  /* .be-editor {
    width: 96%;
    margin: 10px auto;
  } */

  .icons {
    margin: 0 15px;
  }

  .icon {
    width: 56.91px;
  }

  .events.card-panel {
    padding: 5px 0;
  }

  .evt-ctrl {
    padding-top: 15px;
  }

  .app-modal .card {
    margin: 10px auto;
  }

  .subevent {
    align-items: flex-end;
  }

  .subevent .img-wrapper {
    display: flex;
  }

  .subevent .img-wrapper img{
    top: 0
  }

  .selected-icon-indicator {
    position: relative;
    top: 10px;
  }

  .evt {
    padding: 0px 5px 5px;
  }

  .evt-ctrl {
    padding-top: 45px;
}


}
