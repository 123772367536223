.icon-bar.disabled {
   background: #f5f5f5;
   pointer-events: none;
   cursor: not-allowed;
}

.icon-bar.disabled .draggable-item {
  opacity: .3;
}

.draggable-item {
  border-radius: 50%;
  position: static;
  margin: 7px;
  z-index: 1000;
}

.draggable-item:hover {
  cursor: grab;
}

.draggable-item.active {
  background: #f5f5f54d;
  /* padding: 2px;
  border-radius: 50%;
  border: 1px solid silver; */
}

.draggable-item.disabled {
  /* background-color: #3a35344f; */
  /* padding: 2px; */
  /* border-radius: 50%; */
  /* border: 1px solid #846464; */
  opacity: .3;
  cursor: not-allowed;
}
