 input[type=text]:focus+label,  input[type=number]:focus+label, input[type=password]:focus+label {
   color: #000 !important;
 }

 input[type=text]:focus, input[type=number]:focus, input[type=password]:focus {
   border-bottom: 1px solid #1976d2 !important;
   box-shadow: none !important;
 }

 .input-field .prefix.active {
   color: #1976d2 !important;
 }

 .tabs .tab a {
   color: #333333 !important;
 }

 .tabs .tab a:hover {
   background-color: #eee;
   color: #000;
 }

 .tabs .tab a.active {
   background-color: #888;
   color: #000;
 }

 .tabs .indicator {
   background-color: #000;
 }

 .tabs .tab a:focus, .tabs .tab a:focus.active {
   background-color: #f5fcff !important;
 }

 .tabs-content.carousel.carousel-slider {
   height: 480px !important;
 }

 .carousel.carousel-slider .carousel-item {
   display: flex;
   justify-content: center;
 }

 .tabs .indicator {
   background-color: #62b4f5 !important;
 }

 select {
   display: inline !important;
 }

 input[type="checkbox"]:not(:checked), input[type="checkbox"]:checked {
    opacity: 1;
    pointer-events: auto;
}
