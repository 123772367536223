.icons {
  margin: 20px;
  overflow-y: auto;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}

.icon {
  width: 80px;
}

.icon.disabled {
  opacity: 0.2;
  pointer-events: none;
  cursor: not-allowed;
}
